<template>
    <div
        class="content-editor" 
        ref="draggable"
        :data-placement_key="placement_key"
        :data-item_index="index"
        :data-item_code="content.code"
        :class="status"
    >
        <div class="editor-header" ref="editor_header">
            <div class="control-buttons">
                <button class="btn-settings" v-if="canChangeType"  @click="openModal('content_type')" type="button">
                    {{ $t('cms.template') }}
                </button>
                <button class="btn-settings" @click="openModal('content_form')" type="button" :title="$t('controls.edit_properties')">
                    <BaseIcon class="pen"></BaseIcon>
                </button>
                <button class="btn-settings" v-if="content.pagesNum > 0" :class="{warning: content.pagesNum > 1}" @click="openModal('pages_selection')" type="button" :title="$t('cms.pages_with_content')" :disabled="content.pagesNum < 1">
                    <BaseIcon class="blocks"></BaseIcon>
                </button>
                <button v-if="hasStatus('new')" class="btn-settings last-button" @click="removeNewContent()" type="button" :title="$t('cms.delete_content_block')">
                    <BaseIcon class="remove"></BaseIcon>
                </button>
                <button v-else class="btn-settings last-button" @click="openModal('remove_content')" type="button" :title="$t('cms.delete_content_block')">
                    <BaseIcon class="trash"></BaseIcon>
                </button>
            </div>
            
            <div class="spacer"></div>  
            
            <div 
                class="btn-settings handle" 
                v-if="movable" 
                @mousedown="enableDrag('true')"
                @mouseup="enableDrag('false')"
                >
                <BaseIcon class="arrows"></BaseIcon>
            </div>
        </div>
        <div class="edit-area">
            <BlockAdmin :content="content" @updateBlock="updateValues({data: $event})" />
        </div>

        <teleport to="#app">
            <ContentForm :baseData="content"  :opened="openedModal === 'content_form'" @close="closeModal()" @updateValues="updateValues" />
            <ContentTypesSelection :content="content"  :opened="openedModal === 'content_type'" @close="closeModal()" @updateValues="updateValues" />
            <PagesSelection :content="content" :placement_key="placement_key" :index="index"  :opened="openedModal === 'pages_selection'" @close="closeModal()" />
            <RemoveContentConfirmation :content="content" :placement_key="placement_key" :index="index"  :opened="openedModal === 'remove_content'" @close="closeModal()" />
        </teleport>
    </div>
    
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import BlockAdmin from './BlockAdmin';
import useContentDrag from '@admin/composable/ContentDrag';

export default {
    name: "ContentEditor",
    components: {
        ContentForm: defineAsyncComponent(() => import(/* webpackChunkName: "content-form" */'@admin/components/cms/content/ContentForm')),
        ContentTypesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "content-type-selection" */'@admin/components/cms/content/ContentTypeSelection')),
        PagesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "pages-selection" */'@admin/components/cms/content/PagesSelection')),
        RemoveContentConfirmation: defineAsyncComponent(() => import(/* webpackChunkName: "remove-content-confirmation" */'@admin/components/cms/content/RemoveContentConfirmation')),
        BlockAdmin,
    },
    props: {
        content: {
            type: Object,
            default: () => {}
        },
        movable: {
            type: Boolean,
            default: () => true
        },
        placement_key: {
            type: String,
            default: () => ""
        },
        index: {
            type: Number,
            default: () => 0
        },
        status: {
            type: Array,
            default: () => []
        },
    },
    emits: ['updateValues'],
    setup(props,context){
        const store = useStore();
        
        // modal window
        const openedModal = ref(null);
        const openModal = (modal) => {
            openedModal.value = modal;
        }
        const closeModal = () => {
            openedModal.value = null;
        }


        const updateValues = (values) => {
            context.emit('updateValues',values);
            closeModal();
        }

        const { enableDrag,draggable } = useContentDrag();

        const removeNewContent = () => {
            if(props.placement_key !== ''){
                store.commit('page_editor/contentDeleted',{code: props.content.code});
            }
        }

        const hasStatus = (status) => {
            if(props.status.includes(status)){
                return true;
            }
            return false;
        }

        const editor_header = ref(null);
        const headerVisible = ref(false);
        const headerHiding = computed(() => {
            if(!editor_header.value){
                return false;
            }
            return editor_header.value.clientWidth < 240;
        });
        const showHeader = (value) => headerVisible.value = value;

        
        const types = computed(() => store.getters['content_type/getItems']);
        const canChangeType = computed(() => {
            return types.value.some((type) => type.item_key === props.content.typeCode);
        })
      
        return {
            closeModal,
            openModal,
            openedModal,
            updateValues,
            draggable,
            enableDrag,
            removeNewContent,
            hasStatus,
            editor_header,
            headerHiding,
            showHeader,
            headerVisible,
            canChangeType
        }
    }
}
</script>

<style scoped>
.btn-settings{
    background-color: white;
    margin: 0.3rem 0 0 0;
    
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border: 1px solid lightgray;
    transition: background-color var(--transition-ease),visibility var(--transition-ease);
}
.btn-settings:hover{
    background-color: #e9fff3;
}
.btn-settings .icon{
    height: 1rem;
    width: 1rem;
}
.editor-header,.control-buttons{
    display: flex;
    align-items: stretch;
}
.control-buttons{
    overflow: auto;
    scrollbar-width: thin;
}
.control-buttons::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}
 
.control-buttons::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.control-buttons::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 0.3rem;
}
.editor-header button:first-child{
    border-radius: 1rem 0 0 0;
}
.editor-header .last-button{
    border-radius: 0 1rem 0 0;
}
.editor-header button:first-child.last-button{
    border-radius: 1rem 1rem 0 0;
}
/* .content-editor:hover .editor-header{
    visibility: initial;
} */
.edit-area{
    padding: 0.5rem;
    border: 1px solid lightgray;
}
.handle{
    border-radius: 1rem 1rem 0 0;
    cursor:grab;
}
.spacer{
    flex-grow: 1;
}
.btn-settings.warning{
    background: #ffe8bf;
}
.content-editor.added .edit-area,.content-editor.moved .edit-area{
    outline: 3px solid #69dcff;
}
.content-editor.edited .edit-area{
    outline: 3px solid #ffe969;
}
.content-editor.new .edit-area{
    outline: 3px solid #69ffa6;
}
.dragging{ 
    filter: sepia(0.5) blur(0.5px);
    z-index: 100;
}
</style>