import { ref } from 'vue';

const drag = ref(false);

// const dropZones = ref([]);

export default function(){
    
    const draggable = ref(null);


    const startDrag = (evt) => {
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('itemCode', draggable.value.getAttribute('data-item_code'));
        evt.dataTransfer.setData('placementKey', draggable.value.getAttribute('data-placement_key'));
        evt.dataTransfer.setData('itemIndex', draggable.value.getAttribute('data-item_index'));
        draggable.value.classList.add('dragging')
        evt.dataTransfer.setData("text/html", evt.target.outerHTML);

        drag.value = true;
    }

    const onDrop = (evt, placementKey, index,context) => {
        const itemCode = evt.dataTransfer.getData('itemCode');
        if(!itemCode){
            return;
        }
        const itemIndex = parseInt(evt.dataTransfer.getData('itemIndex'));
        const itemParent = evt.dataTransfer.getData('placementKey');
        drag.value = false;
        enableDrag('false');
        if(itemParent === placementKey && index === itemIndex){
            return;
        }
        let position = index;
        if(itemParent === placementKey && position > itemIndex){
            position = position-1;
        }
        context.emit('setPosition',{old_placement: itemParent, old_index: itemIndex,new_placement:placementKey,new_index: position});
    }

    const stopDrag = () => {
        drag.value = false;
        enableDrag('false');
    }

    
    const enableDrag = (value) => {
        if(!draggable.value){
            return;
        }
        draggable.value.setAttribute('draggable', value);
        if(value === "true"){
            draggable.value.addEventListener("dragstart",startDrag);
            draggable.value.addEventListener("dragend",stopDrag);
        } else {
            draggable.value.removeEventListener("dragstart",startDrag);
            draggable.value.removeEventListener("dragend",stopDrag);
            draggable.value.classList.remove('dragging');
        }
        
    }

    return {
        startDrag,
        onDrop,
        stopDrag,
        drag,
        draggable,
        enableDrag
    }

}

