<template>
    <LoadedContent class="content" :loaded="isLoaded" :error="isError">
        <h1 >{{ $t('controls.update_item', {item:contentName}) }}</h1>

        <form class="content_form" @submit.prevent="handleSubmit" >
            <ContentEditor :content="content" @updateValues="updateValues" :movable="false"></ContentEditor>
            
            <PageFooter>
                <div></div>
                <div class="form-buttons">
                    <RouterLinkLocal class="cancel-button" :link="'url.project/:project_code/url.contents'">
                        <BaseButton type="button" class="btn-danger" >
                                {{ $t('controls.cancel') }}
                        </BaseButton>
                    </RouterLinkLocal>
                    <BaseButton type="submit" class="btn-success">{{ $t('controls.save_changes') }}</BaseButton>
                </div>
               
            </PageFooter>
        </form>


    </LoadedContent>
</template>

<script>
import { useStore } from 'vuex';
import LoadedContent from '@common/components/base/LoadedContent';
import PageFooter from '@admin/components/base/PageFooter';
import ContentEditor from '@admin/components/cms/content/ContentEditor';
import { computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "ContentPage",
    components: {
        LoadedContent,
        PageFooter,
        ContentEditor
    },
    setup(){

        const store = useStore();
 
        // const i18n = useI18n();

        const router = useRouter();

        // loading data
        store.dispatch('content_type/fetchItems',{});

        const loadData = () => {
            if(router.currentRoute.value.name !== 'content_edit'){
                return;
            }
            store.dispatch('content/fetchItem');
        };  
        watchEffect(() => loadData());


        
        // getting data
        const baseData = computed(() => store.getters['content/getActiveItem']);

        const content = ref({})

        watchEffect(() => {
            content.value = Object.assign({},baseData.value);
        });

        const isLoaded = computed(() => store.getters["content/isLoaded"]);
        
        const isError = computed(() => store.getters["content/isError"]);

        const contentName = computed(() => store.getters['content/getContentName']);

        const updateValues = (values) => {
            content.value = Object.assign(content.value,values);
        }
        
        const handleSubmit = () => {
            store.dispatch("content/updateItem",{data: content.value,code: content.value.code});
        }
      
        return {
            contentName,
            content,
            isLoaded,
            isError,
            updateValues,
            handleSubmit
        }
    }
}
</script>

<style scoped>
.content,.loaded-content,.content_form{
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 60rem;
}
.content-editor{
    height: 100%;
}
.form-buttons button{
    border-radius: 1.25rem;
    height: 2.5rem;
    margin: 0.3rem 0.5rem;
}
.cancel-button{
    color: white;
}

</style>