<template>
    <BlockDefault 
        v-if="template === 'default'" 
        class="block" :class="[content.class]" 
        @updateBlock="$emit('updateBlock',$event)" 
        :components="components"
        :content="content"
    />
    <BlockCustom class="block"  v-else :content="content" />
</template>

<script>
import { defineAsyncComponent, computed } from 'vue'


export default {
    name: "BlockAdmin",
    components: {
        BlockDefault: defineAsyncComponent(() => import(/* webpackChunkName: "block-default" */ /* webpackPreload: true */'@common/components/cms/blocks/BlockDefault')),
        BlockCustom: defineAsyncComponent(() => import(/* webpackChunkName: "block-custom" */'@admin/components/cms/content/BlockCustom')),
    },
    props: {
        content: {
            type: Object,
            required: true
        },
    },
    emits: ['updateBlock'],
    setup(props){
        const components = computed(() => {
            return {
                text: defineAsyncComponent(() => import(/* webpackChunkName: "text-cell-admin" */ /* webpackPreload: true */'@admin/components/cms/content/cells/TextCellAdmin')),
                image: defineAsyncComponent(() => import(/* webpackChunkName: "image-cell-admin" */ /* webpackPreload: true */'@admin/components/cms/content/cells/ImageCellAdmin')),
                form: defineAsyncComponent(() => import(/* webpackChunkName: "form-cell-admin" */ /* webpackPreload: true */'@admin/components/cms/content/cells/FormCellAdmin')),
            };            
        });

        
        const template = computed(() => {
            if(!props.content || typeof props.content.typeCode !== 'string'){
                return null;
            }
            let cells = props.content.typeCode.split('_');

            return cells[0];
        });
        return {
            components,
            template
        }
    }
}
</script>

<style scoped>
.block{
    width: 100%;
}
.columns2,.columns3{
    display: flex;
    flex-wrap: nowrap;
}
.columns2>div{
    width: 50%;
}
.columns3>div{
    width: 33%;
}
.block>*{
    border: 1px dotted lightgrey;
    margin: 0.3rem
}



</style>
